import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { Dicts } from '../../../../core/interfaces';
import { SspService } from '../../../../core/services/ssp.service';
import { forkJoin } from 'rxjs';
import { TariffsService } from '../../tariffs.service';
import { FormatPricePipe } from '../../../../core/pipes/format-price.pipe';
import { MessageService } from '../../../../core/services/message.service';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { AdxadValidators } from '../../../../core/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-tariff-form',
  templateUrl: './tariff-form.component.html',
  styleUrls: ['./tariff-form.component.scss'],
  providers: [FormatPricePipe]
})
export class TariffFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public isNewTariff = true;
  public dicts = {} as Dicts;

  /** model */
  public models = {
    fixAllGeos: {
      id: 'fix_all_geo',
      title: 'All geos',
      description: 'One price for all countries'
    },
    fixPerGeo: {
      id: 'fix_per_geo',
      title: 'Per geo',
      description: 'Custom prices for each country'
    },
    revshare: {
      id: 'revshare',
      title: 'Revenue',
      description: 'Percent payment for conversions'
    },
    fixPerPeriod: {
      id: 'fix_per_period',
      title: 'Per period',
      description: 'Fixed price for period'
    }
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private fb: UntypedFormBuilder,
    private sspService: SspService,
    private alerts: AlertsComponent,
    private tariffService: TariffsService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.id) {
      this.isNewTariff = false;
    }

    this.createForm();
    this.loadData();
  }

  get rates(): UntypedFormArray {
    return this.form.get('rates') as UntypedFormArray;
  }

  get model(): UntypedFormControl {
    return this.form.get('model') as UntypedFormControl;
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      model: ['fix_all_geo'],
      rates: this.fb.array([]),
      tariffPeriod: [1, [Validators.required, Validators.min(1), Validators.max(366)]]
    });

    if (!this.isNewTariff) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }
  }

  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 300,
      page: 1
    };

    const forkList = [this.sspService.getModels(request), this.sspService.getCountries(request)];

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (results: any) => {
          this.isLoading = false;
          this.dicts.models = results[0];
          this.dicts.geos = results[1];

          if (this.isNewTariff) {
            this.rates.push(
              this.fb.group({
                geo: ['default'],
                desktop: [0.00001, [Validators.required, Validators.min(0.00001), Validators.max(1000000000), Validators.maxLength(16)]],
                tablet: [0.00001, [Validators.required, Validators.min(0.00001), Validators.max(1000000000), Validators.maxLength(16)]],
                mobile: [0.00001, [Validators.required, Validators.min(0.00001), Validators.max(1000000000), Validators.maxLength(16)]]
              })
            );
          }

          if (!this.isNewTariff) {
            this.loadTariff();
          }
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  loadTariff() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.tariffService
      .getTariffForm({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoading = false;

          if (result.status === 'OK') {
            result.data.rates.forEach(x => {
              switch (x.model) {
                case 'revshare': {
                  this.rates.push(
                    this.fb.group({
                      geo: [x.geo, [Validators.required]],
                      desktop: [x.desktop, [Validators.required, AdxadValidators.tariffRates.validatePercent()]],
                      tablet: [x.tablet, [Validators.required, AdxadValidators.tariffRates.validatePercent()]],
                      mobile: [x.mobile, [Validators.required, AdxadValidators.tariffRates.validatePercent()]]
                    })
                  );
                  break;
                }
                case 'fix_per_period': {
                  this.rates.push(
                    this.fb.group({
                      geo: [x.geo, [Validators.required]],
                      desktop: [
                        x.desktop,
                        [Validators.required, Validators.min(0.001), Validators.max(1000000000), Validators.maxLength(16)]
                      ],
                      tablet: x.tablet,
                      mobile: x.mobile
                    })
                  );
                  break;
                }
                default: {
                  this.rates.push(
                    this.fb.group({
                      geo: [x.geo, [Validators.required]],
                      desktop: [
                        x.desktop,
                        [Validators.required, Validators.min(0.00001), Validators.max(1000000000), Validators.maxLength(16)]
                      ],
                      tablet: [
                        x.tablet,
                        [Validators.required, Validators.min(0.00001), Validators.max(1000000000), Validators.maxLength(16)]
                      ],
                      mobile: [
                        x.mobile,
                        [Validators.required, Validators.min(0.00001), Validators.max(1000000000), Validators.maxLength(16)]
                      ]
                    })
                  );
                  break;
                }
              }
            });

            this.form.patchValue(result.data);
          }
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  selectModel(model) {
    this.form.get('model').setValue(model.id);
  }

  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;
    const data = this.form.value;

    const method = this.isNewTariff ? this.tariffService.addTariff(data) : this.tariffService.editTariff(data);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: response => {
        this.isLoadingSubmit = false;
        if (response.status === 'OK') {
          const message = 'Tariff successfully ' + (this.isNewTariff ? 'created' : 'changed');
          this.messageService.add('reload-tariff-grid', { submit: true });
          this.alerts.success(message, 3000);
          this.closeModal(true);
        }
      },
      error: () => (this.isLoadingSubmit = false)
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}

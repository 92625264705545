import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { Dicts, TariffDetail, TariffsDetailView } from '../../../../core/interfaces';
import { SspService } from '../../../../core/services/ssp.service';
import { TariffsService } from '../../tariffs.service';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { MessageService } from '../../../../core/services/message.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-move-all-spots-form',
  templateUrl: './move-all-spots-form.component.html',
  styleUrls: ['./move-all-spots-form.component.scss']
})
export class MoveAllSpotsFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingTariff = false;
  public isLoadingSubmit = false;
  public dicts = {} as Dicts;
  private searchValue = '';
  public tariffDetails: TariffDetail;

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private sspService: SspService,
    private tariffService: TariffsService,
    private alerts: AlertsComponent,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadData();
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      tariffs: [this.config.data.ids],
      newTariff: ['', Validators.required]
    });

    this.form
      .get('newTariff')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => this.loadTariffDetails(result)
      });
  }

  loadTariffDetails(id: string) {
    this.isLoadingTariff = true;
    this.tariffService
      .getTariffDetails({ id: id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: TariffsDetailView) => {
          this.isLoadingTariff = false;
          this.tariffDetails = result.data;
        }
      });
  }

  /**
   * Load tariffs form dicts
   * If error -> close modal
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = { limit: 200, page: 1 };

    const forkList = [this.sspService.getTariffs(request), this.tariffService.getSpotsByTariffs(this.config.data)];

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (results: any) => {
          this.isLoading = false;
          this.dicts.tariffs = results[0];
          this.dicts.spots = results[1];
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  /**
   * Load filtered tariffs dict
   *
   * @param {string} value
   */
  filterTariffs(value: string): void {
    const dict = this.dicts.tariffs;

    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;

    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value
    };

    this.sspService
      .getTariffs(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;
          dict.data = dict.data.concat(result.data);
          dict.isLazy = result.meta ? result.meta.total > result.data.length : false;

          if (result.meta) {
            dict.meta = result.meta;
          }
        },
        error: () => (dict.isLoading = false)
      });
  }

  /**
   * Load tariffs lazy load dict by scroll
   */
  loadTariffs(): void {
    const item = this.dicts.tariffs;

    if (item.isLoading) {
      return;
    }

    item.isLoading = true;

    const request: any = {
      limit: 200,
      page: item.isLazy && item.meta ? item.meta.current_page + 1 : 1
    };

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    this.sspService
      .getTariffs(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          item.isLoading = false;

          item.data = item.data.concat(result.data);

          if (result.meta) {
            item.meta = result.meta;
            item.isLazy = item.meta.total > item.data.length;
          }
        },
        error: () => (item.isLoading = false)
      });
  }

  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;
    const data = this.form.value;

    this.tariffService
      .moveSpots(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (response.status === 'OK') {
            this.isLoadingSubmit = false;

            this.alerts.success("Spot's have been successfully moved", 3000);
            this.messageService.add('reload-tariff-grid', { submit: true });
            this.closeModal();
          }

          if (response.hasOwnProperty('errors')) {
            /** errors can be object or array */
            Object.keys(response.errors).forEach(key => {
              this.alerts.error(response.errors[key], 3000);
            });
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.modalRef.close();
  }
}

<div
  class="loader-mask"
  *ngIf="isLoading || isLoadingStatus"
>
  <adxad-loader [diameter]="40"></adxad-loader>
</div>
<adxad-sidebar-modal-container *ngIf="tariff && !isLoading">
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="closeModal()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>{{ tariff.name }}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      adxadSidebarModalSection
      [hideSeparator]="true"
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Status"
      >
        <adxad-entity-status [entityStatus]="tariff.status"></adxad-entity-status>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block class="col-6">
        <adxad-tariff-model
          [tariff]="tariff"
          [descriptionOnly]="true"
        ></adxad-tariff-model>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Created at"
      >
        {{ tariff.createdAt | date: 'MM/dd/y H:mm:ss' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Updated at"
      >
        {{ tariff.updatedAt | date: 'MM/dd/y H:mm:ss' }}
      </adxad-sidebar-modal-info-block>
    </section>

    <ng-container *ngIf="tariff.model === 'fix_per_period'">
      <adxad-sidebar-modal-bar> Tariff period </adxad-sidebar-modal-bar>

      <adxad-sidebar-modal-info-block class="--tariff-period"> {{ tariffPeriod() }} </adxad-sidebar-modal-info-block>
    </ng-container>

    <adxad-sidebar-modal-bar> Tariff rates </adxad-sidebar-modal-bar>

    <div class="rates">
      <div class="row rates__header-grid">
        <div class="col-6 --countries">Geo</div>
        <div class="col-2">Desktop {{ tariff.model === 'revshare' ? '%' : '$' }}</div>
        <div class="col-2">Tablet {{ tariff.model === 'revshare' ? '%' : '$' }}</div>
        <div class="col-2">Mobile {{ tariff.model === 'revshare' ? '%' : '$' }}</div>
      </div>

      <div
        class="row rates__grid"
        *ngFor="let rate of tariff.rates"
      >
        <div class="col-6 --countries">{{ rate.geo | titlecase }}</div>

        <div class="col-2">{{ rate.desktop }}</div>

        <div class="col-2">{{ rate.tablet }}</div>

        <div class="col-2">{{ rate.mobile }}</div>
      </div>
    </div>

    <ng-container
      *ngIf="
        tariff.status !== tariffStatus.blocked && tariff.spots.length !== 0
      "
    >
      <adxad-sidebar-modal-bar>
        {{ this.tariff.spots.length }} Spots

        <adxad-sidebar-modal-launch-btn
          *ngIf="this.tariff.spots.length && roles.isAdmin()"
          (click)="openSpotsGrid()"
        ></adxad-sidebar-modal-launch-btn>

        <button
          adxadSidebarModalBarAction
          adxadButton
          class="red"
          icon="add"
          (click)="openSpotForm()"
          *ngIf="tariff.status === this.tariffStatus.active"
        >
          {{ 'Create spot' | uppercase }}
        </button>
      </adxad-sidebar-modal-bar>

      <adxad-sidebar-modal-search
        (filter)="filter($event)"
        *ngIf="this.tariff.spots.length"
      ></adxad-sidebar-modal-search>

      <adxad-sidebar-modal-list>
        <div
          class="tariff-view__spot"
          *ngFor="let spot of filtered"
          [class.--is-clickable]="roles.isAdmin()"
          (click)="roles.isAdmin() ? openSpotView(spot.id) : null"
        >
          <adxad-entity-status
            [entityStatus]="spot.status"
            [shortView]="true"
          ></adxad-entity-status>
          {{ spot.name }}
        </div>
      </adxad-sidebar-modal-list>

      <div
        class="no-results"
        *ngIf="this.tariff.spots.length === 0 && !isLoading"
      >
        <img
          class="no-results__icon"
          src="/assets/images/svg/not-found-icon.svg"
          alt="No spots found"
        />
        <div class="no-results__text">No spots found</div>
      </div>
    </ng-container>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <ng-container *ngIf="tariff.status === tariffStatus.pending">
      <button
        adxadSidebarModalAction
        actionType="main-blue"
        (click)="openEditForm()"
      >
        <span class="material-icons">edit</span>
        Edit tariff
      </button>

      <button
        adxadSidebarModalAction
        actionType="main-green"
        (click)="changeStatus(tariffStatus.active)"
      >
        <span class="material-icons">done</span>
        Approve tariff
      </button>

      <button
        adxadSidebarModalAction
        actionType="main-red"
        (click)="changeStatus(tariffStatus.blocked)"
      >
        <span class="material-icons">close</span>
        Reject tariff
      </button>
    </ng-container>

    <ng-container *ngIf="tariff.status === tariffStatus.archive">
      <button
        adxadSidebarModalAction
        [actionType]="tariff.spots.length === 0 ? 'main-red' : 'main-green'"
        (click)="
          changeStatus(
            tariff.spots.length > 0 ? tariffStatus.active : tariffStatus.blocked
          )
        "
      >
        <span class="material-icons"> {{ tariff.spots.length === 0 ? 'stop' : 'play_arrow' }} </span>

        {{ tariff.spots.length === 0 ? 'Block' : 'Activate' }}
      </button>
    </ng-container>

    <button
      *ngIf="tariff.status === tariffStatus.active"
      adxadSidebarModalAction
      actionType="main-red"
      (click)="changeStatus(tariffStatus.archive)"
    >
      <span class="material-icons-outlined">archive</span>
      Archive tariff
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>

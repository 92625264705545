import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { Status, TariffDetail } from '../../../../core/interfaces';
import { AdxadSidebarModalRef } from '../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { AdxadSidebarModalConfig } from '../../../../ui/modules/sidebar-modal/sidebar-modal-config';
import { MessageService } from '../../../../core/services/message.service';
import { TariffsService } from '../../tariffs.service';
import { RolesService } from '../../../../core/services/roles.service';
import { TariffFormComponent } from '../tariff-form/tariff-form.component';
import { takeUntil } from 'rxjs/operators';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { APP_ROUTE } from '../../../../core/routes';
import { Router } from '@angular/router';
import { SpotFormComponent } from '../../../spots/components/spot-form/spot-form.component';
import { BreadcrumbsService } from '../../../../core/services/breadcrumbs.service';
import { SpotViewComponent } from '../../../spots/components/spot-view/spot-view.component';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-tariff-view',
  templateUrl: './tariff-view.component.html',
  styleUrls: ['./tariff-view.component.scss']
})
export class TariffViewComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isLoadingStatus = false;
  public tariff: TariffDetail;
  public tariffStatus = Status;
  public filtered = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadSidebarModalRef,
    private modal: AdxadModal,
    public config: AdxadSidebarModalConfig,
    private sidebarModal: AdxadSidebarModal,
    private alerts: AlertsComponent,
    private messageService: MessageService,
    private tariffsService: TariffsService,
    public roles: RolesService,
    private queryParamsService: QueryParamsService,
    private router: Router,
    public breadcrumbs: BreadcrumbsService
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data || !this.config.data.id) {
      this.closeModal();
    }

    this.queryParamsService.add('sm_tariffView', this.config.data.id);
    this.loadTariffDetails();
  }

  /**
   * Get tariff data
   */
  loadTariffDetails(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.tariffsService
      .getTariffDetails({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.tariff = result.data;
          this.filtered = result.data.spots;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  /**
   * Filter spots
   *
   * @param value
   */
  filter(value: string): void {
    this.filtered = this.tariff.spots.filter(x => x.name.indexOf(value) !== -1);
  }

  /**
   * Close tariff view modal
   * Open detail spot view
   *
   * @param {string} id of project
   */
  openSpotView(id: string): void {
    this.closeModal();

    this.breadcrumbs.add({
      component: TariffViewComponent,
      id: this.config.data.id
    });

    this.sidebarModal.open(SpotViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open filtered spots grid by tariff
   */
  openSpotsGrid() {
    if (!this.tariff.spots.length) {
      return;
    }

    this.router
      .navigate([APP_ROUTE.spots], {
        queryParams: {
          f_tariff: '[{"id":"' + this.tariff.id + '","value":"' + this.tariff.name + '"}]'
        }
      })
      .then(() => this.closeModal());
  }

  /**
   * Open edit tariff form in modal
   */
  openEditForm(): void {
    this.modal
      .open(TariffFormComponent, {
        width: '720px',
        data: this.tariff
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadTariffDetails();
          }
        }
      });
  }

  /**
   * Set active or stopped status
   *
   * @param status
   */
  changeStatus(status: string): void {
    if (status === undefined || this.isLoadingStatus) {
      return;
    }

    this.isLoadingStatus = true;

    const data = {
      ids: [this.config.data.id],
      status: status
    };

    this.tariffsService
      .changeTariffsStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          if (result.status === 'OK') {
            this.isLoadingStatus = false;
            this.tariff.status = result.data[0].status;
            this.messageService.add('reload-tariff-grid', { submit: true });
            let message = 'The tariff is ';
            message += result.data[0].status;
            this.alerts.success(message, 3000);
          }
        },
        error: () => (this.isLoadingStatus = false)
      });
  }

  /**
   * Open create spot form
   */
  openSpotForm(): void {
    this.modal
      .open(SpotFormComponent, {
        width: '680px',
        data: {
          tariff: this.tariff.id
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadTariffDetails();
          }
        }
      });
  }

  tariffPeriod(): string {
    return this.tariff.tariffPeriod + (this.tariff.tariffPeriod > 1 ? " day's" : ' day');
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.modalRef.close();
  }

  ngOnDestroy() {
    this.queryParamsService.remove('sm_tariffView');
  }
}

<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ isNewTariff ? 'Create tariff' : 'Edit ' + config.data.name }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-form-field class="field-group">
      <adxad-input
        formControlName="name"
        label="Tariff name"
        placeholder="Enter tariff name"
      ></adxad-input>
      <adxad-show-errors [control]="form.get('name')"></adxad-show-errors>
    </adxad-form-field>

    <h4 class="adxad-subtitle">Select model</h4>

    <input
      formControlName="model"
      type="hidden"
    />
    <ul class="models">
      <li
        class="models__item"
        [ngClass]="{ selected: form.get('model').value === models[key].id }"
        (click)="selectModel(models[key])"
        *ngFor="let key of models | keys"
      >
        <img
          class="models__item-icon"
          src="/assets/images/svg/{{ models[key].id }}.svg"
          alt="{{ models[key].title }}"
        />

        <div class="models__item-title">
          {{ models[key].title }}

          <div class="models__item-description">{{ models[key].description }}</div>
        </div>
      </li>
    </ul>

    <div
      class="fix-per-period"
      *ngIf="model.value === 'fix_per_period'"
    >
      <h4 class="adxad-subtitle">Set tariff period</h4>

      <div class="row">
        <adxad-form-field class="field-group col-6">
          <adxad-number-input
            formControlName="tariffPeriod"
            step="1"
          ></adxad-number-input>
          <adxad-show-errors [control]="form.get('tariffPeriod')"></adxad-show-errors>
        </adxad-form-field>

        <div class="col-6 fix-per-period__description">Enter the deal duration in days in the left input and it's total cost below.</div>
      </div>
    </div>

    <h4 class="adxad-subtitle">Set rates</h4>

    <!--    Tariff rates      -->
    <adxad-rates-list
      [ratesArray]="rates"
      [geos]="dicts.geos.data"
      [ratesModel]="form.get('model').value"
      ratesType="tariff"
    ></adxad-rates-list>
    <!--   End tariff rates    -->
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      {{ 'cancel' | transloco }}
    </button>

    <button
      adxadButton
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="20"
        *ngIf="isLoadingSubmit"
      ></adxad-loader>
      <span [ngClass]="{ loading: isLoadingSubmit }"> {{ (isNewTariff ? 'create' : 'save') | transloco }} </span>
    </button>
  </adxad-modal-actions>
</ng-container>

<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>Move all spots</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-form-field class="field-group">
      <adxad-select
        label="Tariff"
        placeholder="Select tariff"
        formControlName="newTariff"
        [options]="dicts.tariffs.data"
        [filterable]="true"
        (selectOption)="filterTariffs('')"
        (filterValue)="filterTariffs($event)"
        (scrollOnBottom)="loadTariffs()"
        [lazy]="dicts.tariffs.isLazy"
      ></adxad-select>
      <adxad-show-errors [control]="form.get('newTariff')"></adxad-show-errors>
    </adxad-form-field>

    <adxad-loader
      [diameter]="20"
      *ngIf="isLoadingTariff"
    ></adxad-loader>

    <ng-container *ngIf="tariffDetails && !isLoadingTariff">
      <adxad-label> Selected tariff details </adxad-label>
      <div class="model">
        <img
          src="/assets/images/svg/{{ tariffDetails.model }}.svg"
          class="model__icon"
          alt="{{ tariffDetails.model }}"
        />

        <div class="model__title">
          {{ tariffDetails.model === 'fix_all_geo' ? 'All geos' : tariffDetails.model === 'revshare' ? 'Revenue' : 'Per geo' }}

          <div class="model__description">
            {{ tariffDetails.model === 'fix_all_geo' ? 'One price for all countries' : tariffDetails.model === 'revshare' ? 'Percent payment
            for conversions' : 'Custom prices for each country' }}
          </div>
        </div>
      </div>
    </ng-container>

    <h4 class="adxad-subtitle">Tariff spots</h4>

    <ul class="spots-list">
      <li
        *ngFor="let spot of dicts.spots.data"
        class="spots-list__item"
      >
        {{ spot }}
      </li>
    </ul>
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      Cancel
    </button>

    <button
      adxadButton
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="20"
        *ngIf="isLoadingSubmit"
      ></adxad-loader>

      <span [ngClass]="{ loading: isLoadingSubmit }"> Save </span>
    </button>
  </adxad-modal-actions>
</ng-container>
